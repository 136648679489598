$ipadpro: 1024px;
$tablet: 768px;
$mobile: 414px;
$se_5s: 375px;

@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-screen($break-point) {
  @media screen and (min-width: ($break-point + 1px)) {
    @content;
  }
}

@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: ($break-point-min + 1px)) and (max-width: $break-point-max) {
    @content;
  }
}
