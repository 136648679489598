.box-header {
  padding: 20px 30px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: #f5f6f8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    transition: 0.3s all linear;
  }

  &.is-scroll {
    padding-bottom: 20px;
    opacity: 1;

    &::after {
      opacity: 1;
    }
  }

  @include max-screen($tablet) {
    padding: 15px 15px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
}

.header-content {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.header-logo {
  margin-top: 12px;
  flex: none;
}

// --- header-nav ---------------------------------

.header-nav {
  @include max-screen($tablet) {
    display: none;
  }
}

.header-nav-close {
  display: none;
  width: 100vw;
  height: 100vw;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  &.is-active {
    display: block;
  }
}

.header-nav-list {
  display: flex;
  gap: 45px;
  padding: 21px 50px;
  border-radius: 6px;
  @include fontAlbertSans;
  font-size: 1.4rem;
  background: #fff;
  line-height: 1;
  box-shadow: 10px 10px 30px rgba($c_purple, 0.1);

  &.is-page {
    background: none;
    box-shadow: none;
  }

  @media screen and (min-width: 769px) and (max-width: 1200px) {
    gap: 2vw;
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

.header-nav-content {
  display: flex;
  gap: 8px;
}

.header-nav-list-item {
  font-weight: 500;
}

.header-nav-list-link {
  display: flex;
  transition-property: color, opacity;
  font-weight: bold;

  &:hover,
  &.is-active {
    color: $c_purple;

    &.is-menu::after {
      color: $c_purple;
    }
  }

  &.is-menu {
    &::after {
      @include fa6('\f13a');
      line-height: 1;
      margin-left: 2px;
      color: $c_lightpurple;
      transition: 0.3s all linear;
    }
  }
}

.header-nav-contact-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  border-radius: 6px;
  @include fontAlbertSans;
  font-size: 1.4rem;
  color: #fff;
  background: $c_purple;
  box-shadow: 10px 10px 30px rgba($c_purple, 0.2);
  &:hover,
  &.is-active {
    background: #333;
  }
}

// --- header-sub-nav ---------------------------------

.header-sub-nav {
  display: block; //初期表示用
  padding: 80px 0;
  background: #fff;
  box-shadow: 10px 10px 30px rgba($c_purple, 0.3);
  border-radius: 10px;
  position: absolute;
  top: 110px;
  left: 30px;
  right: 30px;
  pointer-events: none;
  opacity: 0;
  max-height: calc(100vh - 130px);
  overflow: auto;

  &.is-active {
    transition: 0.3s all linear;
    pointer-events: auto;
    opacity: 1;
  }

  .wrapper {
    max-width: 100%;
    @media screen and (max-width: 1160px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.header-sub-nav-item {
}

.header-sub-nav-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 70px;

  &.is-outsidetitle {
    margin-top: 40px;
  }

  .item {
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 100%;
      border-right: 1px solid $c_lightgray;
      position: absolute;
      top: 0;
      right: -35px;
    }

    &:last-of-type::after {
      display: none;
    }
  }
}

.header-sub-nav-heading {
  @include fontAlbertSans(300);
  font-size: 6.0rem;
  color: $c_lightpurple;
  line-height: 1;
  border-bottom: 1px $c_lightgray solid;
  padding-bottom: 27px;
  margin-bottom: 53px;
}

.header-sub-nav-title {
  padding: 18px 20px;
  border-radius: 4px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1;
  background: $c_lightgray;
}

.header-sub-nav-list-item {
  padding: 26px 0;
  border-bottom: 1px $c_lightgray solid;

  .caption {
    display: block;
    margin-left: 24px;
    color: #8b8ba7;
    font-size: 1.2rem;
    margin-top: 3px;
  }
}

.header-sub-nav-list-link {
  &:hover {
    color: $c_purple;
  }

  &::before {
    @include fa6('\f138');
    color: $c_purple;
    margin-right: 10px;
  }

  .text-small {
    font-size: 0.875em;

  }
}

.header-nav-sp {
  display: block;
  height: 100vh;
  padding: 97px 24px 0;
  background: #4d4d4d;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: scroll;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s all ease-in;

  &.is-open {
    opacity: 1;
    pointer-events: auto;
  }

  @include min-screen($tablet) {
    display: none;
  }
}

.header-nav-sp-btn {
  @include min-screen($tablet) {
    display: none;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: $c_purple;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba($c_purple, 0.2);
  position: relative;
  z-index: 999;

  &.is-open {
    .inner {
      &::before,
      &::after {
        top: 0;
        bottom: 0;
      }
      &::before {
        transform: rotate(-45deg);
      }
      &::after {
        transform: rotate(45deg);
      }
    }
    .bar {
      display: none;
    }
  }

  .inner {
    width: 15px;
    height: 10px;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      border-bottom: 1px solid #fff;
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      transition: 0.2s transform linear;
    }

    &::before {
      top: -10px;
    }

    &::after {
      bottom: -10px;
    }
  }

  .bar {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      border-bottom: 1px solid #fff;
      position: absolute;
      top: -0.5px;
      bottom: 0;
      margin: auto;
    }
  }
}
