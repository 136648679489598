@mixin fontNotoSans($weight: 400) {
  font-weight: $weight;
  font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo,
    "ＭＳ ゴシック", sans-serif;
}

@mixin fontAlbertSans($weight: 400) {
  font-weight: $weight;
  font-family: 'Albert Sans', sans-serif;
}

@mixin fontManrope($weight: 400) {
  font-weight: $weight;
  font-family: 'Manrope', sans-serif;
}

// 呼び出す : @include fa6("\f118");
@mixin fa6($icon) {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  content: $icon;
}

@mixin fa6Brand($icon) {
  font-family: "Font Awesome 6 Brands";
  font-weight: 900;
  content: $icon;
}

$positionList: center, left, right;
@each $val in $positionList {
  .t-#{$val} {
    text-align: #{$val};
  }
  .t-#{$val}-sp {
    @include max-screen($tablet) {
      text-align: #{$val};
    }
  }
  .t-#{$val}-pc {
    @include min-screen($tablet) {
      text-align: #{$val};
    }
  }
}

$sized: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
  95, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200;
@each $val in $sized {
  $key: index($sized, $val); // $keyはindex番号（必要であれば使う）
  .mt#{$val} {
    margin-top: #{$val * 0.7}px;
    @include min-screen($tablet) {
      margin-top: #{$val}px;
    }
  }

  .mb#{$val} {
    margin-bottom: #{$val * 0.7}px;
    @include min-screen($tablet) {
      margin-bottom: #{$val}px;
    }
  }

  .pt#{$val} {
    padding-top: #{$val * 0.7}px;
    @include min-screen($tablet) {
      padding-top: #{$val}px;
    }
  }

  .pb#{$val} {
    padding-bottom: #{$val * 0.7}px;
    @include min-screen($tablet) {
      padding-bottom: #{$val}px;
    }
  }
}
