.reason-lead {
  min-height: 1140px;
  padding: 120px 0 0;
  background: url(../img/reason/lead_bg.jpg) no-repeat;
  background-size: cover;
  color: #fff;

  @include max-screen($tablet) {
    min-height: 0;
    padding: 55px 24px 68px;
  }
}

.reason-lead-title {
  font-size: 3.0rem;
  line-height: 1.867;
  font-weight: 500;
  margin-bottom: 50px;

  @include max-screen($tablet) {
    font-size: 2.0rem;
    line-height: 1.75;
    margin-bottom: 35px;
  }
}

.reason-lead-text {
  font-weight: 300;
  line-height: 2.125;

  @include max-screen($tablet) {
    line-height: 1.687;
  }
}

.reason-lead-img {
  display: flex;
  justify-content: center;
  margin-top: 62px;

  @include max-screen($tablet) {
    margin-top: 45px;
  }
}

.reason-sec-lead-content {
  display: flex;
  gap: 60px;
  margin-top: 110px;

  @include max-screen($tablet) {
    display: block;
    padding: 0 24px;
    margin-top: 0;
  }

  .partner-wrap {
    width: 480px;
    flex: none;

    @include max-screen($tablet) {
      width: 100%;
      margin-top: 40px;
    }
  }

  .partner-item {
    padding: 40px;
    background: #fff;
    border-radius: 20px;
    margin-top: 20px;
    box-shadow: -30px 30px 50px rgba($c_purple,0.06);

    @include max-screen($tablet) {
      padding: 32px;
      text-align: center;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  .partner-flex {
    display: flex;
    align-items: center;

    @include max-screen($tablet) {
      justify-content: center;
    }
  }

  .partner-title {
    font-size: 1.8rem;
    font-weight: 500;

    @include max-screen($tablet) {
      font-size: 1.5rem;
    }

    &.is-up {
      margin-bottom: 22px;
    }

    &.is-aside {
      margin-left: 32px;

      @include max-screen($tablet) {
        margin-left: 24px;
      }
    }
  }

  .text {
    font-size: 1.6rem;
    line-height: 2.125;
    font-weight: 300;
    margin-top: 30px;
    letter-spacing: 0.05em;

    @include max-screen($tablet) {
      line-height: 1.687;
      margin-top: 26px;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.reason-sec-lead-title {
  font-size: 3.0rem;
  font-weight: 500;
  margin-bottom: 40px;
  position: relative;

  @include max-screen($tablet) {
    margin-bottom: 26px;
  }

  @include max-screen($tablet) {
    font-size: 2.0rem
  }

  .num {
    @include fontAlbertSans(200);
    font-size: 10.0rem;
    position: absolute;
    left: 0;
    top: -160px;
    color: $c_lightpurple;

    @include max-screen($tablet) {
      font-size: 7.0rem;
      top: -100px;
    }
  }
}

/*----- reason-sec-01 -------------------------*/

.reason-sec-01 {
  padding: 115px 0 100px;

  @include max-screen($tablet) {
    padding-top: 145px;
    padding-bottom: 70px;
  }
}

.reason-archivement-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 90px 0 80px;

  @include max-screen($tablet) {
    margin: 55px 0 ;
    padding: 0 24px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.reason-archivement-list-item {
  min-height: 140px;
  border-right: 1px solid #e5e5f2;
  text-align: center;

  @include max-screen($tablet) {
    border-right: none;
    border-bottom: 1px solid #e5e5f2;
    padding: 24px 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  &:nth-of-type(3n) {
    border-right: none;
  }

  .title {
    font-size: 2.0rem;
    font-weight: 500;
    margin-bottom: 25px;
  }
  .detail {
    font-size: 2.0rem;
    color: #8f8fa7;
  }
  .num {
    @include fontAlbertSans(200);
    font-size: 3em;
    line-height: .7;
  }

  .text {
    font-size: 1.1rem;
    font-weight: 300;
    margin-top: 15px;
    color: #8f8fa7;
  }
}

.reason-performance {
  @include max-screen($tablet) {
    padding: 0 24px;
  }
}

.reason-performance-box {
  background: #e7eaef;
  border-radius: 20px;
  position: relative;
  margin-top: 30px;

  @include max-screen($tablet) {
    border-radius: 10px;
  }

  &:first-of-type {
    margin-top: 0;
  }

  .title-wrap {
    padding: 45px 70px 22px;
    border-bottom: 1px solid #fff;

    @include max-screen($tablet) {
      padding: 18px 25px;
    }
  }

  .title {
    font-size: 2.6rem;
    font-weight: 500;

    @include max-screen($tablet) {
      font-size: 2.0rem;
    }
  }

  .detail-wrap {
    padding: 38px 70px;

    @include max-screen($tablet) {
      padding: 25px;
    }
  }

  .btn-wrap {
    position: absolute;
    top: 35px;
    right: 70px;

    @include max-screen($tablet) {
      top: 18px;
      right: 25px;
    }
  }

  .reason-award-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 26px;

    @include max-screen($tablet) {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .attention-wrap {
    margin-top: 15px;
    text-align: right;
  }

  .attention-text {
    font-size: 1.3rem;
    font-weight: 300;
    color: #8F8FA7;
  }
}

.reason-performance-flex {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 40px;

  @include max-screen($tablet) {
    display: block;
    margin-top: 0;
  }


  .flex-item {
    flex: 1 0 0.49%;
  }
}

/*----- reason-sec-02 -------------------------*/

.reason-sec-02 {
  padding: 90px 0 400px;
  border-top: 1px solid #e7eaef;
  position: relative;

  @include max-screen($tablet) {
    padding-top: 140px;
    padding-bottom: 248px;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 500px;
    background: url(../img/reason/solution_bg.jpg) no-repeat center;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;

    @include max-screen($tablet) {
      width: 100%;
      height: 300px;
      background: url(../img/reason/solution_bg_sp.jpg) no-repeat center;
      background-size: cover;
    }
  }
}

.reason-solution {
  margin-top: 135px;

  @include max-screen($tablet) {
    padding: 0 24px;
    margin-top: 80px;
  }
}

.reason-solution-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @include max-screen($tablet) {
    grid-template-columns: repeat(2, 1fr);
    gap: 60px 10px;
  }

  .item {
    padding: 70px 30px 55px;
    border-radius: 20px;
    background: #fff;
    position: relative;

    @include max-screen($tablet) {
      padding: 60px 18px 30px;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      box-shadow: -30px 30px 50px rgba($c_purple,0.06);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  .baloon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.2em;
    background: $c_purple;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: -60px;
    margin: auto;

    @include max-screen($tablet) {
      width: 75px;
      height: 75px;
      font-size: 1.4rem;
      top: -40px;
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 10px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 10px solid $c_purple;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -19px;
      margin: auto;

      @include max-screen($tablet) {
        border-bottom: 8px solid transparent;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 8px solid $c_purple;
        bottom: -15px;
      }
    }
  }

  .thumb {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 2.0rem;
    font-weight: 500;
    line-height: 1.6;
    text-align: center;
    color: $c_purple;
    margin-top: 22px;

    @include max-screen($tablet) {
      font-size: 1.6rem;
      @include max-screen($tablet) {
        margin-top: 19px;
      }
    }
  }

  .text {
    font-weight: 300;
    line-height: 1.875;
    margin-top: 22px;

    @include max-screen($tablet) {
      font-size: 1.4rem;
      line-height: 1.5;
      margin-top: 12px;
    }
  }

}

/*----- reason-sec-03 -------------------------*/

.reason-sec-03 {
  padding: 110px 0 130px;

  @include max-screen($tablet) {
    padding-top: 140px;
    padding-bottom: 40px;
  }
}

.reason-support {
  margin-top: 100px;

  @include max-screen($tablet) {
    padding: 0 24px;
    margin-top: 50px;
  }
}

.reason-support-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px 20px;

  @include max-screen($tablet) {
    flex-direction: column;
    gap: 15px;
  }

  .item {
    @extend .reason-performance-box;
    margin-top: 0;
    flex-basis: 330px;

    @include max-screen($tablet) {
      flex-basis: auto;
    }

    &.is-wide {
      flex-basis: 400px;

      @include max-screen($tablet) {
        flex-basis: auto;
      }
    }

    .title-wrap {
      padding: 35px 40px 25px;
      @include max-screen($tablet) {
        padding: 18px 25px;
      }
    }

    .title {
      font-size: 2.2rem;
      line-height: 1.636;

      @include max-screen($tablet) {
        font-size: 2.0rem;
      }

      [class^="unit-icon-"] {
        display: flex;
        padding-left: 42px;

        @include max-screen($tablet) {
          padding-left: 35px;
        }

        &::before {
          left: 0;
          top: -7px;
          font-size: 2.8rem;
          position: absolute;

          @include max-screen($tablet) {
            top: 0;
            font-size: 2.0rem;
          }
        }
      }

      .unit-icon-bag::before {
        color: #2954b6;
      }

      .unit-icon-headphone::before {
        color: #b6296c;
      }

      .unit-icon-doc::before {
        color: #b67429;
      }

      .unit-icon-write::before {
        color: #29b674
      }

      .unit-icon-call::before {
        color: #b2b629
      }
    }

    .title-inner {
      display: block;
    }

    .detail-wrap {
      padding: 30px 40px;
      @include max-screen($tablet) {
        padding: 18px 25px;
      }
    }
  }

  .growth-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 180px;
    margin-top: 70px;
    margin-left: 40px;
    background: $c_purple;
    border-radius: 40px;
    text-align: center;
    position: relative;
    z-index: 1;

    @include max-screen($tablet) {
      width: 100%;
      height: auto;
      border-radius: 10px;
      padding: 18px;
      margin: 0;
    }

    &::after {
      content: '';
      display: block;
      width: 1100px;
      height: 410px;
      background: url(../img/reason/growth_arrow.png) no-repeat;
      background-size: cover;
      position: absolute;
      right: -50px;
      top: -130px;
      z-index: -1;
      pointer-events: none;

      @include max-screen($tablet) {
        display: none;
      }
    }
  }

  .growth-inner {

  }

  .growth-title {
    font-size: 2.4rem;
    font-weight: bold;
    color: #fff;

    @include max-screen($tablet) {
      font-size: 2.0rem
    }
  }

  .growth-text {
    @include fontAlbertSans(200);
    font-size: 1.8rem;
    color: #aba9e2;
    margin-top: 10px;
    letter-spacing: 0.2em;
  }
}
