/*----- 見出し -------------------------*/
.unit-heading-01 {
  @include fontAlbertSans(300);
  font-size: 10.0rem;
  line-height: 0.8;

  @include max-screen($tablet) {
    font-size: 5.0rem;
    line-height: 1.2;
  }

  &.is-large {
    font-size: 14.0rem;

    @include max-screen($tablet) {
      font-size: 5.5rem;
    }
  }

  .and {
    @include fontNotoSans(300);
    font-size: 0.714em;
  }

  .accessory {
    font-size: 0.5em;
  }
}

.unit-heading-02 {
  @include fontAlbertSans(300);
  font-size: 4.0rem;
  line-height: 0.8;
}

.unit-heading-03 {
  @include fontManrope(300);
  font-size: 4.0rem;
  line-height: 1.3;
}

.box-under-heading {
  display: flex;
  align-items: center;
  height: 460px;
  padding-top: 55px;

  @include max-screen($tablet) {
    height: 220px;
    padding: 0 24px;
  }
}

.unit-under-heading-01 {
  .heading-ja {
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 12px;

    @include max-screen($tablet) {
      font-size: 1.6rem;
      margin-bottom: 5px;
    }
  }

  .heading-en {
    font-size: 10.0rem;
    @include fontAlbertSans(200);
    color: $c_lightpurple;

    @include max-screen($tablet) {
      font-size: 6.0rem;
    }
  }
}

/*----- タイトル -------------------------*/

.unit-title-01 {
  font-size: 3.0rem;
  line-height: 1;
  font-weight: 500;
  @include max-screen($tablet) {
    font-size: 2.0rem;
    line-height: 1.75;
  }
}

/*----- テキスト -------------------------*/
.unit-text-left {
  text-align: left;
}

.unit-text-right {
  text-align: right;
}

.unit-text-center {
  text-align: center;
}

/*----- ボタン -------------------------*/
.unit-btn-01 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 50px;
  font-size: 1.3rem;
  @include fontAlbertSans(600);
  color: #fff;
  background: $c_purple;
  border-radius: 25px;
  box-shadow: 10px 10px 10px rgba($c_purple, 0.2);

  &.is-small {
    width: 150px;

    @include max-screen($tablet) {
      width: 100px;
      height: 30px;
    }
  }

  &:hover {
    background: #fff;
    color: $c_purple;

    svg {
      fill: $c_purple;
    }
  }
}

/*----- アイコン -------------------------*/
.unit-icon-x {
  width: 14px;
  aspect-ratio: 15.48/14;
  fill: #fff;
  transition: 0.3s all linear;
  margin-right: 1em;
}

.unit-icon-youtube {
  &::before {
    @include fa6Brand('\f16a');
    margin-right: 10px;
  }
}

.unit-icon-note {
  width: 13px;
  aspect-ratio: 13/12.86;
  fill: #fff;
  transition: 0.3s all linear;
  margin-right: 1em;
}

.unit-icon-bag {
  position: relative;
  &::before {
    @include fa6('\f0f2');
  }
}

.unit-icon-headphone {
  position: relative;
  &::before {
    @include fa6('\f025');
  }
}

.unit-icon-doc {
  position: relative;
  &::before {
    @include fa6('\f15c');
    font-weight: 400;
  }
}

.unit-icon-write {
  position: relative;
  &::before {
    @include fa6('\f044');
    font-weight: 400;
  }
}

.unit-icon-call {
  position: relative;
  &::before {
    @include fa6('\f0a1');
  }
}

/*----- リスト -------------------------*/

.unit-list-01 {
  font-size: 1.5rem;
  list-style: disc;
  margin-left: 1.4em;

  @include max-screen($tablet) {
    font-size: 1.3rem;
    font-weight: 300;
  }

  .item {
    margin-bottom: 7px;
    font-weight: inherit;
  }

  &.is-certified {
    font-size: 1.6rem;
    font-weight: 500;
    color: #2d29b6;

    @include max-screen($tablet) {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }
}

/*----- 横並びリスト -------------------------*/
.unit-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.center {
    justify-content: center;
  }

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }
}

.unit-flex-item {
  margin-right: 40px;

  @include max-screen($tablet) {
    margin-right: 20px;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

/*----- footer-cantact -------------------------*/

.unit-footer-contact-link {
  padding-bottom: 2px;
  text-align: center;
  @include max-screen($tablet) {
    padding-bottom: 0;
  }
}

.unit-footer-contact-link-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;

  @include max-screen($tablet) {
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
  }
}

.unit-footer-contact-link-list-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background: #cdd4de;
  position: relative;

  @include max-screen($tablet) {
    justify-content: flex-start;
    height: auto;
    min-height: 130px;
    padding: 0 100px 0 40px;
    text-align: left;
  }

  &:hover {
    background: #e1e5eb;
  }

  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    background: url(../img/common/icon_circle_arrow.svg) no-repeat;
    background-size: cover;
    position: absolute;
    right: 50px;
    top: 0;
    bottom: 0;
    margin: auto;

    @include max-screen($tablet) {
      width: 50px;
      height: 50px;
      right: 34px;
    }
  }

  .inner {
    font-size: 2.0rem;
    line-height: 1.3;

    @include max-screen($tablet) {
      font-size: 1.8rem;
    }

    .notes {
      font-size: 0.65em;
    }
  }
}

/*----- unit-nav / header-nav-sp -------------------------*/

.unit-nav-title {
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 27px;

  @include max-screen($tablet) {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 0;
    border-bottom: 1px solid #666;
  }
}
.unit-nav-title-link {
  @include max-screen($tablet) {
    display: block;
    padding: 12px 0;
    padding-right: 1.2em;
    position: relative;

    &::after {
      @include fa6('\f105');
      position: absolute;
      right: 10px;
    }

    &.is-menu {
      &::before,
      &::after {
        content: '';
        display: block;
        width: 10px;
        height: 2px;
        border-radius: 1px;
        background: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        margin: auto;
      }
      &::after {
        transform: rotate(-90deg);
        transition: 0.3s all linear;
      }
      &.is-active::after  {
        transform: rotate(0);
      }
    }
  }
}

.unit-nav-list {
  display: flex;
  flex-wrap: wrap;
  gap: 65px;

  @include max-screen($tablet) {
    display: block;
  }
}

.unit-nav-sublist-wrap {
  display: flex;
  gap: 40px;

  @include max-screen($tablet) {
    display: block;
  }
}

.unit-nav-sublist {
  font-size: 1.3rem;
}

.unit-nav-sublist-item {
  margin-bottom: 10px;
  white-space: nowrap;

  @include max-screen($tablet) {
    white-space: normal;
    margin-bottom: 0;
    padding-left: 15px;
    border-bottom: 1px solid #666;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

.unit-nav-sublist-link {
  color: #b3b3b3;
  @include min-screen($tablet) {
    &:hover {
      opacity: 0.7;
    }
  }

  @include max-screen($tablet) {
    display: block;
    padding: 12px 0;
    position: relative;
    &::after {
      @include fa6('\f105');
      position: absolute;
      right: 10px;
      color: #fff;
    }
  }
}

.unit-subnav-list {
  display: flex;
  justify-content: center;
  margin-top: 110px;
  font-size: 1.3rem;

  @include max-screen($tablet) {
    flex-wrap: wrap;
    margin-top: 56px;
  }
}

.unit-subnav-list-item {
  white-space: nowrap;
  flex: none;

  &:not(:last-of-type):after {
    content: '|';
    padding: 0 34px;

    @include max-screen($tablet) {
      padding: 0 16px;
    }
  }

}
