.l-body {
  background: $c_bg;
  overflow-x: hidden;
}

.wrapper {
  width: 1100px;
  margin: 0 auto;
  @include max-screen($tablet) {
    width: 100%;
  }
}

/*========= ローディング画面のためのCSS ===============*/
#splash {
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: $c_purple;
  z-index: 9999999;
  text-align: center;
  color: #fff;
  display: none;

  &.is-visible {
    display: flex;
  }
}

#splash-logo {

  @media screen and (max-width: $tablet) {
    max-width: 85%;
  }

  &.is-animate {
    animation: PageAnimeAppear 1s forwards;
  }

  .splash-mission-item {
    font-size: 2.2rem;
    line-height: 1.5;
    font-weight: 300;
    color: #fff;
    letter-spacing: 0.2em;

    @include max-screen($tablet) {
      font-size: 1.8rem;
    }
  }

}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }

  67% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*========= 画面遷移のためのCSS ===============*/

/*画面遷移アニメーション*/
.splashbg{
  display: none;
}

/*bodyにappearクラスがついたら出現*/
body.appear .splashbg {
  display: block;
  content: "";
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  transform: scaleX(0);
  background-color: $c_purple;
  /*伸びる背景色の設定*/
  animation-name: PageAnime;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes PageAnime {
  0% {
    transform-origin: left;
    transform: scaleX(0);
  }

  50% {
    transform-origin: left;
    transform: scaleX(1);
  }

  50.001% {
    transform-origin: right;
  }

  100% {
    transform-origin: right;
    transform: scaleX(0);
  }
}

/*画面遷移の後現れるコンテンツ設定*/
#container {
  opacity: 0;/*はじめは透過0に*/
}

/*bodyにappearクラスがついたら出現*/
body.appear #container {
  animation-name: PageAnimeAppear;
  animation-duration: 1s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes PageAnimeAppear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
