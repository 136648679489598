.box-footer {
  padding: 98px 0 60px;
  color: #b3b3b3;
  background: #4d4d4d;

  @include max-screen($tablet) {
    padding: 50px 24px 25px;
  }
}

.box-footer-contact {
  margin-top: 180px;

  @include max-screen($tablet) {
    margin-top: 85px;
  }
}

.footer-contact-content {
  padding: 0 19px;
}

.footer-contact-heading {
  @include fontManrope;
  font-size: 10.0rem;
  line-height: 0.9;
  @include max-screen($tablet) {
    font-size: 5.0rem;

  }
}

.footer-contact-text {
  line-height: 2.5;
  letter-spacing: 0.08em;
  margin-top: 60px;

  @include max-screen($tablet) {
    line-height: 1.75;
    margin-top: 42px;
  }
}

.footer-contact-link-wrap {
  margin-top: 90px;

  @include max-screen($tablet) {
    margin-top: 50px;
  }
}

// .footer-nav-title {
//   font-size: 1.6rem;
//   font-weight: bold;
//   color: #fff;
//   margin-bottom: 27px;

//   @include max-screen($tablet) {
//     font-size: 1.5rem;
//     font-weight: 400;
//     line-height: 1;
//     margin-bottom: 0;
//     border-bottom: 1px solid #666;
//   }
// }
// .footer-nav-title-link {
//   @include max-screen($tablet) {
//     display: block;
//     padding: 12px 0;
//     padding-right: 1.2em;
//     position: relative;

//     &::after {
//       @include fa6('\f105');
//       position: absolute;
//       right: 10px;
//     }

//     &.is-menu {
//       &::before,
//       &::after {
//         content: '';
//         display: block;
//         width: 10px;
//         height: 2px;
//         border-radius: 1px;
//         background: #fff;
//         position: absolute;
//         top: 0;
//         bottom: 0;
//         right: 10px;
//         margin: auto;
//       }
//       &::after {
//         transform: rotate(-90deg);
//         transition: 0.3s all linear;
//       }
//       &.is-active::after  {
//         transform: rotate(0);
//       }
//     }
//   }
// }

// .footer-nav-list {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 65px;

//   @include max-screen($tablet) {
//     display: block;
//   }
// }

// .footer-nav-sublist-wrap {
//   display: flex;
//   gap: 40px;

//   @include max-screen($tablet) {
//     display: block;
//   }
// }

// .footer-nav-sublist {
//   font-size: 1.3rem;
// }

// .footer-nav-sublist-item {
//   margin-bottom: 10px;
//   white-space: nowrap;

//   @include max-screen($tablet) {
//     white-space: normal;
//     margin-bottom: 0;
//     padding-left: 15px;
//     border-bottom: 1px solid #666;
//   }
//   &:last-of-type {
//     margin-bottom: 0;
//   }
// }

// .footer-nav-sublist-link {
//   @include min-screen($tablet) {
//     &:hover {
//       opacity: 0.7;
//     }
//   }

//   @include max-screen($tablet) {
//     display: block;
//     padding: 12px 0;
//     position: relative;
//     &::after {
//       @include fa6('\f105');
//       position: absolute;
//       right: 10px;
//       color: #fff;
//     }
//   }
// }

// .footer-subnav-list {
//   display: flex;
//   justify-content: center;
//   margin-top: 110px;
//   font-size: 1.3rem;

//   @include max-screen($tablet) {
//     flex-wrap: wrap;
//     margin-top: 56px;
//   }
// }

// .footer-subnav-list-item {
//   white-space: nowrap;
//   flex: none;

//   &:not(:last-of-type):after {
//     content: '|';
//     padding: 0 34px;

//     @include max-screen($tablet) {
//       padding: 0 16px;
//     }
//   }

// }

.footer-partner {
  display: flex;
  gap: 30px;
  margin-top: 104px;

  @include max-screen($tablet) {
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 60px;
    gap: 20px 12px;
  }
}

.footer-partner-item {

  &.is-text {
    order: 2;

    @include max-screen($tablet) {
      order: 1;
      flex: auto;
    }
  }

  &.is-atlassian {
    order: 1;
    flex: none;

    @include max-screen($tablet) {
      order: 2;
      flex: 0 0 192px;
    }
  }

  &.is-vissel {
    order: 3;
    flex: none;

    @include max-screen($tablet) {
      order: 3;
      flex: 0 0 176px;
    }
  }

  .text {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 2;
  }
}

.footer-copy {
  display: block;
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
  margin-top: 70px;

  @include max-screen($tablet) {
    margin-top: 46px;
  }
}
