@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

html,
body {
  @include min-screen($tablet) {}
}

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  height: 100%;
  line-height: 1.7;
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo,
    "ＭＳ ゴシック", sans-serif;
  color: $fontColor;
  background: #fff;
  -webkit-text-size-adjust: 100%;

  @include max-screen($tablet) {
    line-height: 1.5;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

a {
  display: inline-block;
  transition: all 0.3s linear;

  &:not([class]):hover {
    opacity: 0.7;
  }
}

img {
  //  display: block;
}

.sp-visible {
  @include min-screen($tablet) {
    display: none !important;
  }
}

.pc-visible {
  @include max-screen($tablet) {
    display: none !important;
  }
}

.mb-visible {
  @include min-screen($mobile) {
    display: none !important;
  }
}

.tb-visible {
  @include max-screen($mobile) {
    display: none !important;
  }
}
