// ----------  ----------
// ブロック要素汎用クラス
.block {
  display: block;
}

.iblock {
  display: inline-block;
}

.inline {
  display: inline;
}

.pc-block {
  display: block;
  @include max-screen($tablet) {
    display: none;
  }
}

.pc-iblock {
  display: inline-block;
  @include max-screen($tablet) {
    display: none;
  }
}

.sp-block {
  display: none;
  @include max-screen($tablet) {
    display: block;
  }
}

.sp-iblock {
  display: none;
  @include max-screen($tablet) {
    display: inline-block;
  }
}

.sp-visible {
  @media screen and (min-width: 769px) {
    display: none !important;
  }
}

.pc-visible {
  @include max-screen($tablet) {
    display: none !important;
  }
}
