.top-main {
}

.top-mv {
  min-height: 100vh;
  border-bottom: 1px solid #e0e0ef;
  position: relative;
  overflow: hidden;

  @include max-screen($tablet) {
    min-height: 0;
  }

  &::before {
    content: '';
    display: block;
    width: 2000px;
    height: 940px;
    background: url(../img/top/mv_bg_wave.png) no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: -50%;
    right: -50%;
    margin: auto;
    z-index: -2;

    @include max-screen($tablet) {
      width: 100%;
      aspect-ratio: 214 / 310;
      max-height: 620px;
      background: url(../img/top/mv_bg_wave_sp.png) no-repeat;
      background-size: cover;
    }
  }
}

.top-mv-parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  @include max-screen($tablet) {
    max-height: 620px;
  }

  .layer {
    display: block;
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
    z-index: -1;

    &::after {
      content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: auto;
			transform: translateX(-50%) translateY(-50%);
      z-index: -1;
    }

    &.is-animate {
      animation: 3s animFloat infinite;
    }
	}

  .layer-01 {
    &::after {
      width: 1607px;
      height: 739px;
      background: url(../img/top/mv_bg_poly_01.png) no-repeat;
      background-size: cover;

      @include max-screen($tablet) {
        width: 495px;
        height: 489px;
        background: url(../img/top/mv_bg_poly_01_sp.png) no-repeat;
        background-size: cover;
      }
    }
  }
  .layer-02 {
    &::after {
      width: 1194px;
      height: 809px;
      background: url(../img/top/mv_bg_poly_02.png) no-repeat;
      background-size: cover;

      @include max-screen($tablet) {
        width: 440px;
        height: 384px;
        background: url(../img/top/mv_bg_poly_02_sp.png) no-repeat;
        background-size: cover;
      }
    }

    &.is-animate {
      animation-delay: 1s;
    }
  }
}

@keyframes animFloat {
0%, 100% {transform: translateY(0);}
50% {transform: translateY(-15px);}
}

.top-mv-mission-wrap {
  display: flex;
  justify-content: center;
  min-height: 850px;
  padding-top: 165px;
  position: relative;

  @include max-screen($tablet) {
    align-items: center;
    padding-top: 0;
    min-height: 590px;
    padding-bottom: 60px;
  }
}

.top-mv-mission {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  @include fontAlbertSans(100);
  font-size: 18.0rem;
  line-height: 0.8;
  color: $c_purple;
  transform: translateZ(0);

  @include max-screen($tablet) {
    font-size: 7.0rem;
  }
}

.top-mv-mission-item {
  display: block;
  margin-top: 25px;
  color: transparent;
  transform: translateZ(0);

  @include max-screen($tablet) {
    margin-top: 20px;
  }

  &.is-animate {
    animation: animTextBlur 8s ease forwards;
    @include max-screen($tablet) {
      animation: animTextBlurSP 8s ease forwards;
    }
  }

  &:nth-child(1) {
    animation-delay: 0.12s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.3s;
  }

  &:first-of-type {
    margin-top: 0;
  }
}

@keyframes animTextBlur {
0%		{text-shadow:  0 0 100px $c_purple; opacity:0;}
5%		{text-shadow:  0 0 90px $c_purple;}
15%		{opacity: 1;}
20%		{text-shadow:  0 0 0px $c_purple;}
100%	{text-shadow:  0 0 0px $c_purple; opacity: 1;}
}

@keyframes animTextBlurSP {
0%		{
        filter: drop-shadow(0 0 20px rgba($c_purple, 1));
        color: rgba($c_purple, 0);
      }
5%		{
        filter: drop-shadow(0 0 10px rgba($c_purple, 0.8));
      }
15%		{
        color: rgba($c_purple, 1);
      }
20%		{
        filter: drop-shadow(0 0 0px rgba($c_purple, 0.4));
      }
100%	{
  filter: drop-shadow(0 0 0px rgba($c_purple, 0.2));
        color: rgba($c_purple, 1);
      }
}

.top-mv-catch {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  font-size: 2.6rem;
  line-height: 1;
  font-weight: 300;

  position: absolute;
  left: 0;
  bottom: 50px;
  z-index: 0;

  @include max-screen($tablet) {
    font-size: 1.8rem;
    bottom: 75px;
    gap: 15px;
  }
}

.top-mv-catch-item {
  display: block;
  padding: 13px 24px;
  padding-left: 4.28%;
  color: transparent;

  @include max-screen($tablet) {
    padding: 10px 18px;
    padding-left: 42px;
  }

  &.is-fadeIn {
    color: #fff;
    position: relative;
    overflow: hidden;

    .inner {
      display: block;
      overflow: hidden;
      transform: translateX(-100%);
      animation: text_slideIn 0.5s cubic-bezier(0.77, 0, 0.175, 1) 1s forwards;
    }

    .text {
      display: block;
      transform: translateX(100%);
      animation: text_slideIn 0.5s cubic-bezier(0.77, 0, 0.175, 1) 1s forwards;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: #000000;
      transform: scaleX(0);
      transform-origin: 0 0;
      animation: mask_fadeIn 1.0s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
    }
  }
}

@keyframes mask_fadeIn {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(100%);
  }
}

@keyframes text_slideIn {
  100% {
    transform: translateX(0);
  }
}

.top-mv-pickup {
  display: flex;
  justify-content: flex-end;

  @include max-screen($tablet) {
    display: block;
  }

}

.top-mv-pickup-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 45px;

  @include max-screen($tablet) {
    display: block;
    margin-bottom: 20px;
    margin-right: 0;
    padding: 0 24px
  }

  .heading {
    @include fontAlbertSans(500);
  }

  .link {
    @include max-screen($tablet) {
      display: flex;
      align-items: center;
    }

    &:hover {
      opacity: 0.7;
    }

    &::after {
      @include fa6('\f105');
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin-top: 25px;
      border-radius: 50%;
      background: #e0e0ef;
      color: #8b8ba7;

      @include max-screen($tablet) {
        margin-top: 0;
        margin-left: 14px;
      }
    }
  }
}

.top-mv-pickup-list {
  display: flex;

  @include max-screen($tablet) {
    display: block;
  }
}

.top-mv-pickup-list-item {
  width: 300px;
  min-height: 200px;
  padding: 35px 40px 50px;
  border-left: 1px solid #e0e0ef;

  @include max-screen($tablet) {
    width: auto;
    min-height: 0;
    border-left: none;
    border-top: 1px solid #e0e0ef;
    padding: 18px 24px 20px;
  }

  .time {
    display: block;
    @include fontAlbertSans(400);
    font-size: 1.2rem;
    color: #8b8ba7;
    margin-bottom: 25px;
    @include max-screen($tablet) {
      margin-bottom: 9px;
    }
  }

  .title {
    font-size: 1.5rem;
    line-height: 1.73;
    font-weight: 300;

    @include max-screen($tablet) {
      line-height: 1.56;
    }
  }

  .link {
    &:hover {
      color: #8b8ba7;
      text-decoration: underline;
    }
  }
}

.splash-mission {
  @extend .top-mv-mission;

  .top-mv-mission-item {
    color: #fff;
  }
}

.top-sec-wrap-01 {
  padding: 160px 0 170px;
  position: relative;
  overflow: hidden;

  @include max-screen($tablet) {
    padding-top: 72px;
    padding-bottom: 75px;
  }

  &::after {
    content: '';
    display: block;
    width: 2500px;
    height: 1600px;
    background: url(../img/top/sec_bg_01.png) no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: -550px;
    z-index: -1;

    @include max-screen($tablet) {
      width: 100%;
      height: auto;
      aspect-ratio: 428 / 900;
      background: url(../img/top/sec_bg_01_sp.png) no-repeat;
      background-size: cover;
      left: 0;
      right: 0;
    }
  }
}

.top-sec-wrap-02 {
  background: #cdd4de;
  padding: 190px 0 100px;
  position: relative;
  overflow: hidden;

  @include max-screen($tablet) {
    padding-top: 57px;
    padding-bottom: 60px;
  }

  &::after {
    content: '';
    display: block;
    width: 1461px;
    height: 1294px;
    background: url(../img/top/sec_bg_02.png) no-repeat;
    background-size: cover;
    position: absolute;
    top: 68px;
    right: -336px;
    z-index: 0;

    @include max-screen($tablet) {
      width: 731px;
      height: auto;
      aspect-ratio: 731 / 647;
      background: url(../img/top/sec_bg_02.png) no-repeat;
      background-size: cover;
      top: 77px;
      left: 24px;
      right: auto;
    }

  }
}

// Mission -----------------------------

.sec-top-mission {
  @include max-screen($tablet) {
    padding: 0 24px;
  }
}

.top-mission-heading {
  text-align: right;
  margin-right: -90px;
  color: $c_lightpurple;
  font-weight: 200;

  @include max-screen($tablet) {
    text-align: left;
    margin-right: 0;
  }
}

.top-mission-content {
  display: flex;
  justify-content: space-between;
  margin-top: 134px;

  @include max-screen($tablet) {
    display: block;
    margin-top: 50px;
  }

  .img-wrap {
    width: 560px;
    margin-left: -150px;
    margin-top: 80px;
    order: 1;

    @include max-screen($tablet) {
      width: 81.77%;
      margin: 50px auto 0;
    }
  }

  .text-wrap {
    width: 550px;
    order: 2;

    @include max-screen($tablet) {
      width: 100%;
    }
  }

  .text {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2.56;

    @include max-screen($tablet) {
      font-size: 1.6rem;
      line-height: 1.875;
    }
  }
}

.top-mission-title {
  font-size: 4.0rem;
  line-height: 1.75;
  font-weight: 500;
  margin-bottom: 60px;

  @include max-screen($tablet) {
    font-size: 3.0rem;
    line-height: 1.5;
    margin-bottom: 32px;
  }
}

.top-mission-btn-wrap {
  margin-top: 90px;
  @include max-screen($tablet) {
    margin-top: 36px;
  }
}

// About -----------------------------

.sec-top-about {
  margin-top: 170px;

  @include max-screen($tablet) {
    padding: 0 24px;
    margin-top: 60px;
  }
}

.top-about-heading {
  margin-bottom: 100px;
  color: $c_lightpurple;

  @include max-screen($tablet) {
    margin-bottom: 28px;
  }
}

.top-about-text {
  font-size: 1.6rem;
  font-weight: 300;
  margin-top: 70px;

  @include max-screen($tablet) {
    line-height: 1.875;
    margin-top: 28px;
    text-align: justify;
    letter-spacing: 0.05em;
  }
}

// Product -----------------------------

.sec-top-product {
  position: relative;
  z-index: 1;
  @include max-screen($tablet) {
    padding: 0 24px;
  }
}

.top-product-lead {
  .text {
    font-size: 1.6rem;
    line-height: 2.5;
    font-weight: 300;

    @include max-screen($tablet) {
      line-height: 1.875;
    }
  }
}

.top-product-heading {
  margin-bottom: 75px;

  @include max-screen($tablet) {
    margin-bottom: 30px;
  }
}

.top-product-list-wrap {
  margin-top: 60px;

  @include max-screen($tablet) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 50px;
  }
}

.top-product-dots {
  text-align: right;
  margin-bottom: 70px;

  @include max-screen($tablet) {
    margin-top: 42px;
    margin-bottom: 0;
  }

  .slick-dots {
    display: flex;
    justify-content: flex-end;
    gap: 53px;

    @include max-screen($tablet) {
      justify-content: center;
      gap: 35px;
    }

    li {
      width: 5px;
      height: 5px;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #e1e5eb;
        position: absolute;
        top: -26px;
        left: -26px;
        opacity: 0;
        transition: 0.3s all ease-in;
        @include max-screen($tablet) {
          width: 40px;
          height: 40px;
          top: -17.5px;
          left: -17.5px;
        }
      }

      &.slick-active::after {
        opacity: 1;
      }
    }

    button {
      text-indent: -9999px;
      display: block;
      border: none;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: 0;
      padding: 0;
      position: relative;
      z-index: 1;
      cursor: pointer;
      outline: none;
      -webkit-tap-highlight-color:rgba(0,0,0,0);

      @include max-screen($tablet) {
        width: 5px;
        height: 5px;
      }
    }
  }
}

.top-product-list {
  width: calc(1100px + ((100% - 1100px) / 2));
  margin-left: auto;
  position: relative;

  @include max-screen($tablet) {
    width: 100%;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: auto !important;
  }

  .slick-list {
    border-radius: 30px 0 0 30px;

    @include max-screen($tablet) {
      border-radius: 0;
    }
  }
}

.top-product-list-item {
  background: url(../img/top/product_bg.jpg) no-repeat;
  background-size: cover;

  @include max-screen($tablet) {
    background: url(../img/top/product_bg_sp.jpg) no-repeat;
    background-size: cover;
    border-radius: 20px;
    overflow: hidden;
  }

  &.item02 {
    background: url(../img/top/product_bg_02.jpg) no-repeat;
    background-size: cover;

    @include max-screen($tablet) {
      background: url(../img/top/product_bg_02_sp.jpg) no-repeat;
      background-size: cover;
    }

  }

  &.item03 {
    background: url(../img/top/product_bg_03.jpg) no-repeat;
    background-size: cover;

    @include max-screen($tablet) {
      background: url(../img/top/product_bg_03_sp.jpg) no-repeat;
      background-size: cover;
    }

  }

  &.item04 {
    background: url(../img/top/product_bg_04.jpg) no-repeat;
    background-size: cover;

    @include max-screen($tablet) {
      background: url(../img/top/product_bg_04_sp.jpg) no-repeat;
      background-size: cover;
    }

  }
}

.top-product-list-content {
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
  padding-left: 90px;

  @include max-screen($tablet) {
    display: block;
    padding: 48px 30px 45px;
  }

  .inner {
    padding-left: 40px;
    padding-right: 60px;
    padding: 0;
  }

  .text-wrap {
    flex: none;
    width: 560px;
    margin-top: 80px;

    @include max-screen($tablet) {
      width: auto;
      margin-top: 0;
    }
  }

  .text {
    font-size: 1.6rem;
    line-height: 1.875;
    margin: 0 60px 0 40px;
    @include max-screen($tablet) {
      margin: 0 10px;
      font-size: 1.5rem;
      line-height: 1.667;
    }
  }

  .img-wrap {
    @include max-screen($tablet) {
      margin-top: 20px;
      text-align: center;

      img {
        margin: 0 auto;
      }
    }
  }
}

.top-product-list-subtitle {
  text-align: center;
  font-size: 2.2rem;
  font-weight: 500;
  margin-bottom: 12px;

  @include max-screen($tablet) {
    font-size: 1.8rem;
    margin-bottom: 0;
  }
}

.top-product-list-title {
  // margin-left: -40px;
  // margin-right: -60px;
  margin-bottom: 32px;

  @include max-screen($tablet) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 14px;
  }
}

.top-product-list-btn-wrap {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  @include max-screen($tablet) {
    display: none;
  }
}

.top-product-list-btn-wrap-sp {
  display: none;
  @include max-screen($tablet) {
    display: flex;
    justify-content: center;
    margin-top: 27px;
  }
}

// Case study -----------------------------

.sec-top-case {
  margin-top: 210px;
  padding-bottom: 50px;
  overflow: hidden;

  @include max-screen($tablet) {
    padding: 0 24px 20px;
    margin-top: 80px;
  }
}

.top-case-lead {
  .text {
    font-size: 1.6rem;
    line-height: 2.5;
    font-weight: 300;

    @include max-screen($tablet) {
      line-height: 1.875;
      width: calc(100% - 150px);
    }
  }
}

.top-case-heading {
  margin-bottom: 75px;

  @include max-screen($tablet) {
    margin-bottom: 27px;
  }
}

.top-case-list-wrap {
  margin-top: 115px;
  @include max-screen($tablet) {
    margin-top: 36px;
  }
}

.top-case-arrows-wrap {
  position: relative;
}
.top-case-arrows {
  display: flex;
  gap: 20px;
  position: absolute;
  top: -210px;
  right: 0;

  @include max-screen($tablet) {
    top: -97px;
  }

  .slick-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    padding: 0;
    text-indent: -9999px;
    font-size: 0;
    border: 1px solid #ebeef2;
    border-radius: 50%;
    background: none;
    cursor: pointer;
    transition: 0.3s all linear;

    @include max-screen($tablet) {
      width: 50px;
      height: 50px;
    }

    &:hover {
      background: #e1e5eb;
    }
  }

  .slick-prev::before {
    @include fa6('\f104');
    color: #ebeef2;
    font-size: 1.4rem;
    text-indent: 0;
  }

  .slick-next::before {
    @include fa6('\f105');
    color: #ebeef2;
    font-size: 1.4rem;
    text-indent: 0;
  }
}

.top-case-list {
  width: 100vw;

  .slick-list {
    padding-bottom: 30px;
  }

  .slick-track {
    display: flex;
    gap: 50px;

    @include max-screen($tablet) {
      gap: 20px;
    }
  }

  .slick-slide {
    height: auto !important;
  }
}

.top-case-list-item {
  width: 400px;
  background: #fff;
  border-radius: 6px 6px 10px 10px;
  box-shadow: 10px 10px 10px rgba($c_purple, 0.1);

  @include max-screen($tablet) {
    width: 330px;
  }

  .case-thumb {
    border-radius: 6px;
    overflow: hidden;
  }

  .case-inner {
    padding: 20px 30px 30px;

    .text {
      font-size: 1.5rem;
      line-height: 1.73;
      color: #666;
    }
  }
}

.top-case-list-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #e6e6e6;

  @include max-screen($tablet) {
    height: 65px;
    padding: 0 15px;
  }
}

.top-case-list-title {
  font-size: 2.0rem;
  font-weight: 500;
  margin-bottom: 12px;
}

.top-case-btn-wrap {
  display: flex;
  justify-content: center;
  margin-top: 60px;

  @include max-screen($tablet) {
    margin-top: 30px;
  }
}

// Search -----------------------------

.sec-top-search {
  margin-top: 160px;

  @include max-screen($tablet) {
    margin-top: 50px;
  }
}

.top-search-content {
  width: calc(1100px + ((100% - 1100px) / 2));
  margin-left: auto;

  @include max-screen($tablet) {
    width: 100%;
    padding-left: 24px;
  }
}

.top-search-btn {
  display: block;
  width: 100%;
  border-radius: 22px 0 0 22px;
  background: rgba(255, 255, 255, 1);
  color: #fff;
  background: url(../img/top/search_btn_hover.jpg) no-repeat;
  background-size: cover;
  box-shadow: -30px 30px 50px rgba($c_purple, 0.06);
  overflow: hidden;
  position: relative;

  // &::after {
  //   content: '';
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   background: url(../img/top/search_btn_hover.jpg) no-repeat;
  //   background-size: cover;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: -1;
  //   opacity: 0;
  //   transition: 0.3s;
  // }

  &:hover {
    // color: #fff;
    // background: rgba(255, 255, 255, 0);
    // &::after {
    //   opacity: 1;
    // }
    opacity: 0.7;
  }

  .search-inner {
    width: 1100px;
    padding: 80px 120px 80px 80px;
    position: relative;

    @include max-screen($tablet) {
      width: 100%;
      padding: 50px 30px 45px;
    }

    &::after {
      display: flex;
      justify-content: center;
      align-items: center;
      @include fa6('\f105');
      width: 80px;
      height: 80px;
      color: #8b8ba7 !important;
      background: #fff;
      border: 1px solid #d8d8eb;
      border-radius: 50%;
      position: absolute;
      right: -40px;
      top: 0;
      bottom: 0;
      margin: auto;

      @include max-screen($tablet) {
        width: 50px;
        height: 50px;
        top: 40px;
        right: 30px;
        margin: 0;
      }
    }

    .text {
      font-size: 1.6rem;
      line-height: 2.125;
      font-weight: 300;

      @include max-screen($tablet) {
        font-size: 1.5rem;
        line-height: 1.93;
        text-align: justify;
      }
    }
  }
}

.top-search-heading {
  font-size: 3.0rem;
  font-weight: 500;
  margin-bottom: 40px;

  @include max-screen($tablet) {
    font-size: 2.0rem;
    margin-bottom: 35px;
  }
}

// Why -----------------------------

.sec-top-why {
  margin-top: 230px;

  @include max-screen($tablet) {
    padding: 0 24px;
    margin-top: 95px;
  }
}

.top-why-lead {
  display: flex;
  gap: 70px;

  @include max-screen($tablet) {
    display: block;
  }

  .text {
    font-size: 2.0rem;
    line-height: 1.9;
    font-weight: 400;
    margin-top: 15px;

    @include max-screen($tablet) {
      font-size: 1.6rem;
      line-height: 1.75;
      font-weight: 300;
    }
  }
}

.top-why-content {
  margin-top: 130px;

  @include max-screen($tablet) {
    margin-top: 45px;
  }
}

.top-why-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 60px;
  margin: 0 -80px;

  @include max-screen($tablet) {
    margin: 0;
    gap: 30px;
  }
}

.top-why-list-item {
  display: flex;
  background: #e7eaef;
  border-radius: 40px;
  overflow: hidden;

  @include max-screen($tablet) {
    display: block;
    border-radius: 15px;
  }

  .text-wrap {
    display: flex;
    align-items: center;
    padding: 0 110px;

    @include max-screen($tablet) {
      padding: 40px 30px 50px;
    }

    .text {
      font-size: 1.6rem;
      line-height: 2.25;
      font-weight: 300;
      margin-top: 78px;

      @include max-screen($tablet) {
        margin-top: 30px;
        line-height: 1.75;
      }
    }
  }

  .img-wrap {
    flex: none;
  }
}

.top-why-list-title {
  font-size: 2.5rem;
  line-height: 1.52;
}

// Topics -----------------------------

.sec-top-topics {
  margin-top: 190px;
  padding-bottom: 110px;
  border-bottom: 1px solid #e7eaef;

  @include max-screen($tablet) {
    padding: 0 24px;
    margin-top: 75px;
  }
}

.top-topics-content {
  position: relative;
  @include max-screen($tablet) {
    padding-bottom: 60px;
  }
}

.top-topics-lead {

  .text {
    font-size: 1.6rem;
    line-height: 2.5;
    font-weight: 300;
    margin-top: 74px;

    @include max-screen($tablet) {
      line-height: 1.75;
      margin-top: 35px;
    }
  }
}

.top-topics-list-wrap {
  margin-top: 62px;

  @include max-screen($tablet) {
    margin-top: 36px;
  }
}

.top-topics-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px 40px;

  @include max-screen($tablet) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

.top-topics-list-item {
  .thumb {
    border-radius: 10px;
    overflow: hidden;
  }

  .topics-wrap {
    margin-top: 28px;

    @include max-screen($tablet) {
      margin-top: 14px;
    }

    .info-wrap {
      display: flex;
      color: #8b8ba7;
      margin-top: 24px;

      @include max-screen($tablet) {
        font-size: 1.2rem;
        margin-top: 12px;
      }

      .info-time {
        display: flex;
        @include fontAlbertSans(400);

        &::after {
          content: '';
          display: block;
          height: 1em;
          margin: 5px 20px;
          border-right: 1px solid $c_lightpurple;

          @include max-screen($tablet) {
            margin: 5px 15px;
          }
        }
      }

      .info-cat-list {
        display: flex;
        gap: 20px;

        @include max-screen($tablet) {
          gap: 5px 10px;
          flex-wrap: wrap;
        }
      }

      .info-cat-list-item {

      }
    }
  }
}

.top-topics-list-title {
  font-size: 1.8rem;
  font-weight: 500;

  @include max-screen($tablet) {
    font-size: 1.5rem;
    line-height: 1.53;

  }

  .link:hover {
    color: #8b8ba7;
  }
}

.top-topics-btn-wrap {
  position: absolute;
  top: 15px;
  right: 0;
  @include max-screen($tablet) {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    position: static;
  }
}

// News -----------------------------

.sec-top-news {
  margin-top: 140px;

  @include max-screen($tablet) {
    padding: 0 24px;
    margin-top: 50px;
  }
}

.top-news-content {
  margin: 0 -80px;
  padding: 110px 80px 70px;
  border-radius: 40px;
  background: #fff;

  @include max-screen($tablet) {
    margin: 0;
    padding: 45px 17px 35px;
    border-radius: 15px;
  }

  .inner {
    display: flex;
    gap: 130px;

    @include max-screen($tablet) {
      display: block;
    }
  }

  .nav-wrap {
    width: 120px;
    flex: none;

    @include max-screen($tablet) {
      width: auto;
      margin-bottom: 40px;
    }
  }

  .news-wrap {

  }
}

.top-news-nav-select-wrap {
  margin: 0 20px;
  color: #8b8ba7;
  position: relative;
  margin-top: 35px;
  &::after {
    content: '\25bc';
    position: absolute;
    top: 5px;
    right: 25px;
    pointer-events: none;
  }

  @include min-screen($tablet) {
    display: none;
  }
}

.top-news-nav-select {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 0 25px;
  border: 1px solid #d8d8eb;
  border-radius: 30px;
  color: #8b8ba7;
}

.top-news-nav-list {
  margin-top: 60px;
  padding-top: 55px;
  border-top: 1px solid #e6e6e6;
  font-size: 1.5rem;
  line-height: 1;
  @include max-screen($tablet) {
    display: none;
  }
}

.top-news-nav-list-item {
  margin-bottom: 23px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.top-news-nav-list-link {
  color: #808080;

  &:hover,
  &.is-active {
    color: #000;
    text-decoration: underline;
  }
}

.top-news-list {
  display: none;

  &.is-active {
    display: block;
  }
}

.top-news-list-item {
  border-bottom: 1px dashed #e6e6e6;

  .news-inner {
    display: flex;
    align-items: center;
    padding: 32px 20px 32px 0;

    @include max-screen($tablet) {
      flex-wrap: wrap;
      padding: 20px 0;
    }
  }

  &:first-of-type {
    margin-top: -10px;

    .news-inner {
      padding-top: 0;

    }
  }

  .time-wrap {
    margin-right: 38px;
    @include fontManrope(400);
    line-height: 1;
    text-align: center;

    @include max-screen($tablet) {
      display: flex;
      margin-right: 18px;
    }
  }

  .time-month {
    display: block;
    font-size: 1.3rem;
    margin-bottom: 3px;

    @include max-screen($tablet) {
      font-size: 1.2rem;
      margin-bottom: 0;
      &::after {
        content: '.';
        margin-left: -0.2em;
      }
    }
  }

  .time-day {
    display: block;
    font-size: 4.0rem;

    @include max-screen($tablet) {
      font-size: 1.2rem;
    }
  }

  .cat-wrap {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .cat-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 40px;
    border-radius: 20px;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1;
    background: #e8e8ed;

    @include max-screen($tablet) {
      height: 30px;
    }
  }

  .title-wrap {
    width: 100%;
  }
}


.top-news-list-link {
  &:hover {
    background: #f6f6f8;

    .top-news-title {
      color: #8b8ba7;
    }
  }
}

.top-news-title {
  text-align: justify;
  font-size: 1.5rem;
  line-height: 2;
  font-weight: 300;

  @include max-screen($tablet) {
    font-size: 1.4rem;
    line-height: 1.5;
    margin-top: 8px;
  }
}

.top-news-btn-wrap {
  display: flex;
  justify-content: center;
  margin-top: 42px;

  @include max-screen($tablet) {
    margin-top: 25px;
  }
}

// SNS -----------------------------

.sec-top-sns {
  margin-top: 60px;

  @include max-screen($tablet) {
    padding: 0 24px;
  }
}

.top-sns-content {
  margin: 0 -80px;
  padding: 110px 80px 70px;
  border-radius: 40px;
  background: #fff;

  @include max-screen($tablet) {
    margin: 0;
    padding: 20px 0;
    border-radius: 15px;
  }
}

.heading-wrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  border-bottom: 1px solid #e6e6e6;

  @include max-screen($tablet) {
    display: block;
    padding: 0 15px 25px;
  }
}

.top-sns-btn-list {
  display: flex;
  gap: 15px;

  @include max-screen($tablet) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 36px;
  }
}

.top-sns-btn-list-btn {
  width: 200px;

  @include max-screen($tablet) {
    width: auto;
  }
}

.top-sns-tips-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-top: 60px;

  @include max-screen($tablet) {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px 20px;
    padding: 0 20px;
    margin-top: 45px;
  }
}

.top-sns-tips-list-item {
  .thumb {
    border-radius: 10px;
    overflow: hidden;
  }
}

.top-sns-tips-list-link:hover {
  opacity: 0.7;
}

.top-sns-tips-list-title {
  font-size: 1.8rem;
  line-height: 1.67;
  font-weight: 500;
  margin-top: 22px;

  @include max-screen($tablet) {
    font-size: 1.4rem;
    line-height: 1.5;
    margin-top: 10px;
  }
}
